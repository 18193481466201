import "../App.css";
import { AcademicCapIcon, GlobeAsiaAustraliaIcon, DocumentChartBarIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";

const ItemTile = (props: { id: string; title: any; subject: any; grade: any; type: any }) => {
	const { id, title, subject, grade, type } = props;
	const itemId = id;
	console.log(itemId);
	return (
		<div className="flex-row items-center justify-start bg-blue-200 px-6 pt-6 pb-4 shadow-lg rounded-md relative w-[500px]">
			<span className="flex items-center justify-start gap-4 text-lg font-semibold pb-4 ">
				<h2>{title}</h2>
			</span>
			<span className="flex items-center justify-start gap-4 pb-4 font-medium">
				<AcademicCapIcon className="w-6 h-6" />
				<h3>{subject}</h3>
			</span>
			<span className="flex items-center justify-start gap-4 pb-4 font-medium">
				<GlobeAsiaAustraliaIcon className="w-6 h-6" />
				<h3>{grade}</h3>
			</span>
			<span className="flex items-center justify-start gap-4 pb-4 font-medium">
				<DocumentChartBarIcon className="w-6 h-6" />
				<h3>{type}</h3>
			</span>
			<span className="flex items-center justify-end gap-4 font-medium cursor-pointer hover:text-gray-500 duration-100 absolute bottom-4 right-6">
				<ArrowLongRightIcon className="w-6 h-6" />
				<a href={"/search/" + itemId} className="underline decoration-blue-500 underline-offset-2 decoration-4">
					Go now
				</a>
			</span>
		</div>
	);
};

export default ItemTile;
