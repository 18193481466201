import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./Home";
import Search from "./Search";
import SearchResult from "./SearchResult";
import Support from "./Support";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />}>
				<Route index element={<Home />} />
				<Route path="search" element={<Search />} />
				<Route path="support" element={<Support />} />
				<Route path="search/:id" element={<SearchResult />} />
			</Route>
		</Routes>
	</BrowserRouter>
);
