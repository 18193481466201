import React from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
let backgroundImage = require("./images/background.png");

const Home = () => {
	const navigate = useNavigate();

	return (
		<div
			className="w-full h-full overflow-y-hidden"
			style={{ backgroundImage: `url(${backgroundImage})` }} // Set the background image
		>
			{/* Main Content */}
			<div className="flex">
				<div className="w-3/4 px-32 py-12">
					<span className="w-1/2">
						<h1 className="font-extrabold xl:h-28 text-transparent text-5xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300">
							Effortlessly Access Quality Materials
						</h1>
					</span>
					<p className="xl:w-2/3 font-medium text-lg text-white">
						Our resource search system makes it easy to find the resources you need, exactly when you need them. With advanced search features and a comprehensive database of materials, you'll always have the tools you need to succeed.
					</p>

					<div className="pt-16">
						<button
							className="bg-blue-600 hover:bg-blue-700 duration-200 font-bold text-lg py-2 px-4 shadow-lg rounded-xl text-white"
							onClick={() => {
								navigate("/search");
							}}>
							Search Now
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
