import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import useWindowDimensions from "./components/utilities/windowDimensions";
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
const logo = require("./images/logo.png");

const firebaseConfig = {
	apiKey: "AIzaSyDvdREDn8E5H0FXaloMujPl3myUcWaJly0",
	authDomain: "studystash-ccdb5.firebaseapp.com",
	projectId: "studystash-ccdb5",
	storageBucket: "studystash-ccdb5.appspot.com",
	messagingSenderId: "523639584421",
	appId: "1:523639584421:web:152e1ef6a000dd1e51ff4c",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

const App = () => {
	const { pathname } = useLocation();
	const { width } = useWindowDimensions();
	if (width < 768) {
		console.log("On mobile, website not currently supported");
		return (
			<div className="w-screen h-screen flex flex-col justify-center items-center px-10 font-medium">
				<div className="leading-2">
					<h1 className="leading-tight text-4xl font-semibold text-text">
						Mobile Website unavailable<br></br>
					</h1>
					<hr className="mt-6 mb-12 border-4 border-contrast w-20"></hr>
					<p className="leading-loose">
						<br></br>
						<br></br>Mobile design is planned for v2, not for v1. Please view site on a desktop!
					</p>
				</div>
			</div>
		);
	} else {
		return (
			<div className="w-screen h-screen overflow-x-hidden overflow-y-hidden">
				{/* Header navbar. Inspiration taken from https://flowbite.com/docs/components/navbar/ */}
				<nav className="bg-white border-gray-200 dark:bg-gray-900">
					<div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3">
						<a href="/" className="flex items-center">
							<img src={logo} className="h-8 mr-3" alt="StudyStash Logo" />
							<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">StudyStash</span>
						</a>
						<button
							data-collapse-toggle="navbar-default"
							type="button"
							className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
							aria-controls="navbar-default"
							aria-expanded="false">
							<span className="sr-only">Open main menu</span>
							<svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
							</svg>
						</button>
						<div className="hidden w-full sm:block sm:w-auto" id="navbar-default">
							<ul className="font-medium flex flex-col p-4 sm:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 sm:flex-row sm:space-x-8 sm:mt-0 sm:border-0 sm:bg-white dark:bg-gray-800 sm:dark:bg-gray-900 dark:border-gray-700">
								<li>
									<a
										href="/"
										className={
											pathname === "/"
												? "block py-2 pl-3 pr-4 text-white bg-blue-700 rounded sm:bg-transparent sm:text-blue-700 sm:p-0 dark:text-white sm:dark:text-blue-500"
												: "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 sm:hover:bg-transparent sm:border-0 sm:hover:text-blue-700 sm:p-0 dark:text-white sm:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent"
										}>
										Home
									</a>
								</li>
								<li>
									<a
										href="/search"
										className={
											pathname === "/search"
												? "block py-2 pl-3 pr-4 text-white bg-blue-700 rounded sm:bg-transparent sm:text-blue-700 sm:p-0 dark:text-white sm:dark:text-blue-500"
												: "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 sm:hover:bg-transparent sm:border-0 sm:hover:text-blue-700 sm:p-0 dark:text-white sm:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent"
										}>
										Search
									</a>
								</li>
								<li>
									<a
										href="/support"
										className={
											pathname === "/support"
												? "block py-2 pl-3 pr-4 text-white bg-blue-700 rounded sm:bg-transparent sm:text-blue-700 sm:p-0 dark:text-white sm:dark:text-blue-500"
												: "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 sm:hover:bg-transparent sm:border-0 sm:hover:text-blue-700 sm:p-0 dark:text-white sm:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent"
										}>
										Support
									</a>
								</li>
							</ul>
						</div>
					</div>
				</nav>

				{/* Footer */}
				<footer className="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-4 dark:bg-gray-800 dark:border-gray-600">
					<span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
						© 2023{" "}
						<a href="https://bradyjoosse.com/" className="hover:underline">
							StudyStash™
						</a>
						. All Rights Reserved.
					</span>
					<ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
						<li>
							<a href="/support" className="mr-4 hover:underline md:mr-6">
								Support
							</a>
						</li>
						<li>
							<a href="mailto:23bj@student.nbcs.nsw.edu.au" className="hover:underline">
								Contact
							</a>
						</li>
					</ul>
				</footer>
				<Outlet />
			</div>
		);
	}
};

export default App;
