import React from "react";
import "./App.css";
const image = require("./images/support.png");

const Home = () => {
	return (
		<div className="w-full h-full overflow-y-auto">
			{/* Main Content */}
			<div className="w-3/4 px-32 py-12">
				<span className="w-1/2">
					<h1 className="font-extrabold xl:h-28 text-transparent text-5xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">Support Page // FAQs</h1>
				</span>
				<h2 className="font-bold text-xl pb-2">How do I find resources?</h2>
				<p className="xl:w-2/3 font-medium text-lg">
					By navigating to the{" "}
					<a className="underline decoration-slate-700" href="/search">
						Search Page
					</a>
					, you can find all the relevant materials for your favourite HSC courses by searching using the search field as well as the drop-down filters.
				</p>
				<h2 className="font-bold text-xl pb-2 pt-12">How do I use the search filters?</h2>
				<p className="xl:w-2/3 font-medium text-lg">
					There are various search filters available for users to narrow down relevant materials, including:
					<ul className="list-disc pl-8 pt-2 pb-4">
						<li className="indent-1">Type</li>
						<li className="indent-1">Subject</li>
						<li className="indent-1">Year</li>
					</ul>
					These filters can be accessed by first clicking on them, and then selecting the relevant option. Please note only one option can be selected at a time. Your selected option for each drop-down will be shown once you click off the
					drop-down.
				</p>

				<h2 className="font-bold text-xl pb-2 pt-12">How do I download materials?</h2>
				<p className="xl:w-2/3 font-medium text-lg">
					By clicking the "Go now" button on the resource cards on the{" "}
					<a className="underline decoration-slate-700" href="/search">
						Search Page
					</a>
					, you can navigate to the relevant page for that particular resource. An example of this can be seen below:
					<img src={image} alt="support" className="w-2/3 pt-4 pb-4" />
				</p>
				<p className="xl:w-2/3 font-medium text-lg pb-4">
					By clicking on the "Download" Button, you can download the relevant PDF file and open it using any PDF viewing application you like.<br></br>By clicking the "Back to Search" button you can return to the Search page to find more
					materials.
				</p>
				<h2 className="font-bold text-xl pb-2 pt-12">My question hasn't been answered, where do I get more help?</h2>
				<p className="xl:w-2/3 font-medium text-lg pb-24">
					If your question isn't answered here, please feel free to contact our team directly by either clicking the "Contact" link in the webpage footer or by emailing{" "}
					<a className="underline decoration-slate-700" href="mailto:23bj@student.nbcs.nsw.edu.au">
						23bj@student.nbcs.nsw.edu.au
					</a>{" "}
					directly.
				</p>
			</div>
		</div>
	);
};

export default Home;
