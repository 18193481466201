/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./App.css";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import ItemTile from "./components/ItemTile";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { collection, getDocs, getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";

// Defining an item type for encapsulating the db structure
type ItemType = {
	id: string;
	title: string;
	subject: string;
	grade: string;
	type: string;
};

const Search = () => {
	// Setting up firebase
	const firebaseConfig = {
		apiKey: "AIzaSyDvdREDn8E5H0FXaloMujPl3myUcWaJly0",
		authDomain: "studystash-ccdb5.firebaseapp.com",
		projectId: "studystash-ccdb5",
		storageBucket: "studystash-ccdb5.appspot.com",
		messagingSenderId: "523639584421",
		appId: "1:523639584421:web:152e1ef6a000dd1e51ff4c",
	};

	const app = initializeApp(firebaseConfig);
	const firestore = getFirestore(app);

	// A usestate for encapsulating all of the items to be displayed from the DB
	const [items, setItems] = useState<ItemType[]>([]);

	// Gets all items on page load
	useEffect(() => {
		const fetchItems = async () => {
			const itemsCollection = collection(firestore, "resources");
			const itemSnapshot = await getDocs(itemsCollection);
			const itemList = itemSnapshot.docs.map((doc) => {
				return {
					id: doc.id,
					...doc.data(),
				} as ItemType;
			});
			setItems(itemList);
		};
		fetchItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// function to add classes to a current class
	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	// useStates to capture user search params
	const [searchValue, setSearchValue] = useState<string>("");
	const [selectedType, setSelectedType] = useState<string>("");
	const [selectedSubject, setSelectedSubject] = useState<string>("");
	const [selectedYear, setSelectedYear] = useState<string>("");

	const handleSearch = () => {
		console.log(searchValue);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const filteredItems: ItemType[] = items.filter(
		(item) =>
		  (!searchValue || item.title.toLowerCase().includes(searchValue.toLowerCase())) &&
		  (!selectedType || item.type === selectedType) &&
		  (!selectedSubject || item.subject === selectedSubject) &&
		  (!selectedYear || item.grade === selectedYear)
	  );
	  

	return (
		<div className="w-screen h-screen flex flex-col items-center overflow-y-auto ">
			<h1 className="font-bold text-3xl pt-12">StudyStash - Search</h1>

			{/* Search box input field */}
			<div className="flex items-center gap-4 pt-8 w-1/3">
				<input
					onChange={handleChange}
					placeholder="Search for resources..."
					className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></input>
				<button onClick={handleSearch} className="rounded-lg bg-blue-600 shadow-lg px-4 py-2 text-white w-32 h-12 hover:bg-blue-700 duration-200">
					Search
				</button>
			</div>

			{/* Selector buttons - inspiration from https://tailwindui.com/components/application-ui/elements/dropdowns */}
			<div className="w-1/3 flex items-center justify-between gap-6 pt-8 text-white">
				<Menu as="div" className="relative inline-block text-left w-56 xl:w-45">
					<div>
						<Menu.Button
							className={
								"inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:opacity-90 duration-100 " +
								(selectedType ? "bg-blue-500" : "bg-gray-300")
							}>
							{selectedType || "Type"}
							<ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
						</Menu.Button>
					</div>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedType("")} className={classNames(selectedType === "" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedType === "" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											None
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedType("Notes")} className={classNames(selectedType === "Notes" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedType === "Notes" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Notes
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={() => setSelectedType("Topic Test")}
											className={classNames(selectedType === "Topic Test" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedType === "Topic Test" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Topic Test
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={() => setSelectedType("Assesment Task")}
											className={classNames(selectedType === "Assesment Task" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedType === "Assesment Task" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Assesment Task
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedType("Other")} className={classNames(selectedType === "Other" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedType === "Other" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Other
										</a>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>

				<Menu as="div" className="relative inline-block text-left w-56 xl:w-60">
					<div>
						<Menu.Button
							className={
								"inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:opacity-90 duration-100 " +
								(selectedSubject ? "bg-blue-500" : "bg-gray-300")
							}>
							{selectedSubject || "Subject"}
							<ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
						</Menu.Button>
					</div>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedSubject("")} className={classNames(selectedSubject === "" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											None
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedSubject("SDD")} className={classNames(selectedSubject === "SDD" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "SDD" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											SDD
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedSubject("IPT")} className={classNames(selectedSubject === "IPT" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "IPT" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											IPT
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={() => setSelectedSubject("Engineering")}
											className={classNames(selectedSubject === "Engineering" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "Engineering" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Engineering
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={() => setSelectedSubject("Maths Advanced")}
											className={classNames(selectedSubject === "Maths Advanced" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "Maths Advanced" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Maths Advanced
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={() => setSelectedSubject("English Standard")}
											className={classNames(selectedSubject === "English Standard" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedSubject === "English Standard" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											English Standard
										</a>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>

				<Menu as="div" className="relative inline-block text-left w-56 xl:w-45">
					<div>
						<Menu.Button
							className={
								"inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-300 hover:opacity-90 duration-100 " +
								(selectedYear ? "bg-blue-500" : "bg-gray-300")
							}>
							{selectedYear || "Year"}
							<ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
						</Menu.Button>
					</div>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95">
						<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedYear("")} className={classNames(selectedYear === "" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedYear === "" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											None
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedYear("11")} className={classNames(selectedYear === "11" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedYear === "11" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Year 11
										</a>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<a onClick={() => setSelectedYear("12")} className={classNames(selectedYear === "12" ? "bg-blue-700 text-white" : active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block px-4 py-2 text-sm")}>
											{selectedYear === "12" && <CheckIcon className="h-5 w-5 text-white inline-block mr-2" />}
											Year 12
										</a>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>

			{/* Returned results */}
			<div className="flex flex-wrap justify-center gap-6 p-8 pb-44">
				
				{filteredItems.length === 0 ? (
					<p>No results found.</p>
				) : (
					filteredItems.map((item: ItemType, index: number) => (
						<ItemTile key={index} title={item.title} type={item.type} subject={item.subject} grade={item.grade} id={item.id} />
					))
				)}
			</div>

		</div>
	);
};

export default Search;
