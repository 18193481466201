import React, { useEffect, useState } from "react";
import "./App.css";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { ArrowLongLeftIcon, CloudArrowDownIcon } from "@heroicons/react/20/solid";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Defining an item type for encapsulating the db structure
type ItemType = {
	id: string;
	title: string;
	subject: string;
	grade: string;
	type: string;
	description: string;
};

const SearchResult = () => {
	const navigate = useNavigate();
	const { id = "" } = useParams();
	const [item, setItem] = useState<ItemType | null>(null);

	// initialising firebase
	const firebaseConfig = {
		apiKey: "AIzaSyDvdREDn8E5H0FXaloMujPl3myUcWaJly0",
		authDomain: "studystash-ccdb5.firebaseapp.com",
		projectId: "studystash-ccdb5",
		storageBucket: "studystash-ccdb5.appspot.com",
		messagingSenderId: "523639584421",
		appId: "1:523639584421:web:152e1ef6a000dd1e51ff4c",
	};

	const app = initializeApp(firebaseConfig);
	const firestore = getFirestore(app);
	const storage = getStorage(app);

	// gets the data on page load to paint to canvas
	useEffect(() => {
		const fetchItem = async () => {
			const itemDoc = doc(firestore, "resources", id);
			const itemSnapshot = await getDoc(itemDoc);
			if (itemSnapshot.exists()) {
				setItem({ id: itemSnapshot.id, ...itemSnapshot.data() } as ItemType);
			} else {
				console.log("No such document!");
				navigate(-1);
			}
		};
		fetchItem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firestore, id]);

	// downloads corresponding file from firebase storage upon clicking download button
	const handleDownload = async () => {
		if (!item) {
			console.error("No item to download");
			return;
		}

		const fileRef = ref(storage, `${item.id}.pdf`);

		try {
			const url = await getDownloadURL(fileRef);
			// Programmatically download the file to the user's device
			const a = document.createElement("a");
			a.href = url;
			a.target="_blank";
			a.download = `${item.id}.pdf`;
			a.click();
		} catch (error) {
			console.error(`Failed to download item ${item.id}.pdf:`, error);
		}
	};

	return (
		<div className="w-full h-full black">
			{/* Main Content */}
			<div className="px-2 py-5 flex-row justify-center">
				<h1 className="font-extrabold justify-center flex xl:h-20 text-transparent text-5xl bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">{item?.title}</h1>
				<div className="grid grid-cols-3 justify-between px-36 w-full">
					<div className="flex flex-col items-center justify-center">
						<p className="font-semibold text-lg underline decoration-blue-700 decoration-4 underline-offset-2">Course</p>
						<p className="text-xl pt-2">{item?.subject}</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<p className="font-semibold text-lg underline decoration-blue-700 decoration-4 underline-offset-2">Grade</p>
						<p className="text-xl pt-2">{item?.grade}</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<p className="font-semibold text-lg underline decoration-blue-700 decoration-4 underline-offset-2">Type</p>
						<p className="text-xl pt-2">{item?.type}</p>
					</div>
				</div>
			</div>

			<span className="flex justify-center">
				<hr className="my-5 h-0.5 w-2/3 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />
			</span>

			<div className="w-5/6 px-36">
				<h2 className="font-bold text-2xl">Description</h2>
				<p>{item?.description}</p>
			</div>

			{/* Functionality buttons */}
			<div className="flex items-center w-1/2 justify-start gap-8 pl-36 pt-8 ">
				<button
					onClick={() => {
						navigate(-1);
					}}
					className="flex bg-blue-500 px-6 py-2 rounded-xl shadow-lg font-semibold text-white">
					<ArrowLongLeftIcon className="w-8 h-6" />
					<p className="">Back to Search</p>
				</button>
				<button
					onClick={() => {
						handleDownload();
					}}
					className="flex bg-blue-600 px-6 py-2 rounded-xl shadow-lg font-semibold text-white">
					<CloudArrowDownIcon className="w-8 h-6" />
					<p className="">Download</p>
				</button>
			</div>
		</div>
	);
};

export default SearchResult;
